import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import objectPath from "object-path";
import Select from "react-select";
import { useHistory, useLocation } from "react-router-dom";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { FaUser } from "react-icons/fa";
import { reactSelectCustom } from "../../../../app/constant";
import { PATH } from "../../../../app/config";
import { useSelector } from "react-redux";
import { mainLogo } from "../../../assets";

export function HeaderMobile() {
  let policesData = useSelector((state) => state?.clinicsReducer);
  const uiService = useHtmlClassService();
  const history = useHistory();
  const location = useLocation();
  const isSectionPermissionAllowed = (value) => {
    return (
      policesData?.getSidebarPolicesByRoleIdData?.[0]?.policies?.filter(
        (items) => items?.value.startsWith(value)
      ).length > 0
    );
  };
  const [dashboard, setDashboard] = useState({});
  const layoutProps = useMemo(() => {
    return {
      headerLogo: uiService.getStickyLogo(),
      asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
      headerMenuSelfDisplay:
        objectPath.get(uiService.config, "header.menu.self.display") === true,
      headerMobileCssClasses: uiService.getClasses("header_mobile", true),
      headerMobileAttributes: uiService.getAttributes("header_mobile"),
    };
  }, [uiService]);
  const manueOptions = [
    { value: 1, label: "Global Dashboard" },
    { value: 2, label: "Immunization" },
    { value: 3, label: "Antenatal Care" },
    { value: 4, label: "OPD Over Five" },
    { value: 5, label: "OPD Under Five" },
    { value: 6, label: "Primary Health Unit" },
    { value: 7, label: "Hospital InPatient Register" },
    { value: 8, label: "Posta Natal Care" },
    { value: 9, label: "Labour Delivery Maternity" },
    { value: 10, label: "Wash Register" },
  ];


  const handleOnChange = ({ value }) => {
    if (value) {
      if (value === 1) {
        return history.push(PATH.MAIN_DASHBOARD);
      }
      if (value === 2) {
        return history.push(PATH.IMMUNIZATION_DASHBOARD);
      }
      if (value === 3) {
        return history.push(PATH.ANC_DASHBOARD);
      }
      if (value === 4) {
        return history.push(PATH.OPD_FIVE_REGISTER_DASHBOARD);
      }
      if (value === 5) {
        return history.push(PATH.OPD_UNDER_FIVE_REGISTER_DASHBOARD);
      }
      if (value === 6) {
        return history.push(PATH.PRIMARY_HEALTH_UNIT_DASHBOARD);
      }
      if (value === 7) {
        return history.push(PATH.HOSPITAL_INPATIENT_REGISTER_DASHBOARD);
      }
      if (value === 8) {
        return history.push(PATH.POSTNATAL_CARE_DASHBOARD);
      }
      if (value === 9) {
        return history.push(PATH.LABOUR_DELIVERY_MATERNITY_DASHBOARD);
      }
      if (value === 10) {
        return history.push(PATH.WASH_REGISTER_DASHBOARD);
      }
    }
  };
  let modifiedOptions = [];
  if (isSectionPermissionAllowed("gd"))
    modifiedOptions = [...modifiedOptions, manueOptions[0]];
  if (isSectionPermissionAllowed("id"))
    modifiedOptions = [...modifiedOptions, manueOptions[1]];
  if (isSectionPermissionAllowed("acd"))
    modifiedOptions = [...modifiedOptions, manueOptions[2]];
  if (isSectionPermissionAllowed("acd"))
    modifiedOptions = [...modifiedOptions, manueOptions[3]];
  if (isSectionPermissionAllowed("acd"))
    modifiedOptions = [...modifiedOptions, manueOptions[4]];
  if (isSectionPermissionAllowed("acd"))
    modifiedOptions = [...modifiedOptions, manueOptions[5]];
  if (isSectionPermissionAllowed("acd"))
    modifiedOptions = [...modifiedOptions, manueOptions[6]];
  if (isSectionPermissionAllowed("acd"))
    modifiedOptions = [...modifiedOptions, manueOptions[7]];
  if (isSectionPermissionAllowed("acd"))
    modifiedOptions = [...modifiedOptions, manueOptions[8]];
  if (isSectionPermissionAllowed("acd"))
    modifiedOptions = [...modifiedOptions, manueOptions[9]];

  const handleDashboard = (current) => {
    let option = [...modifiedOptions];
    if (current === PATH.IMMUNIZATION_DASHBOARD) {
      if (option.length > 2) return setDashboard(option[1]);
      else return setDashboard(option[0]);
    }
    if (current === PATH.ANC_DASHBOARD) {
      return setDashboard(option[option.length - 8]);
    }
    if (current === PATH.OPD_FIVE_REGISTER_DASHBOARD) {
      return setDashboard(option[option.length - 7]);
    }
    if (current === PATH.OPD_UNDER_FIVE_REGISTER_DASHBOARD) {
      return setDashboard(option[option.length - 6]);
    }
    if (current === PATH.PRIMARY_HEALTH_UNIT_DASHBOARD) {
      return setDashboard(option[option.length - 5]);
    }
    if (current === PATH.HOSPITAL_INPATIENT_REGISTER_DASHBOARD) {
      return setDashboard(option[option.length - 4]);
    }
    if (current === PATH.POSTNATAL_CARE_DASHBOARD) {
      return setDashboard(option[option.length - 3]);
    }
    if (current === PATH.LABOUR_DELIVERY_MATERNITY_DASHBOARD) {
      return setDashboard(option[option.length - 2]);
    }
    if (current === PATH.WASH_REGISTER_DASHBOARD) {
      return setDashboard(option[option.length - 1]);
    }

    return setDashboard(option[0]);
  };
  useEffect(() => {
    handleDashboard(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  useEffect(() => {
    let option = [...modifiedOptions];
    ;
    if (location.pathname === PATH.MAIN_DASHBOARD) {
      return setDashboard({ value: 1, label: "Global Dashboard" });
    }
    if (location.pathname === PATH.IMMUNIZATION_DASHBOARD) {
      return setDashboard({ value: 2, label: "Immunization" });
    }
    if (location.pathname === PATH.ANC_DASHBOARD) {
      return setDashboard({ value: 3, label: "Antenatal Care" });
    }
    if (location.pathname === PATH.OPD_FIVE_REGISTER_DASHBOARD) {
      return setDashboard({ value: 4, label: "OPD Over Five" });
    }
    if (location.pathname === PATH.OPD_UNDER_FIVE_REGISTER_DASHBOARD) {
      return setDashboard({ value: 5, label: "OPD Under Five" });
    }
    if (location.pathname === PATH.PRIMARY_HEALTH_UNIT_DASHBOARD) {
      return setDashboard({ value: 6, label: "Primary Health Unit" });
    }
    if (location.pathname === PATH.HOSPITAL_INPATIENT_REGISTER_DASHBOARD) {
      return setDashboard({ value: 7, label: "Hospital InPatient Register" });
    }
    if (location.pathname === PATH.POSTNATAL_CARE_DASHBOARD) {
      return setDashboard({ value: 8, label: "Posta Natal Care" });
    }
    if (location.pathname === PATH.LABOUR_DELIVERY_MATERNITY_DASHBOARD) {
      return setDashboard({ value: 9, label: "Labour Delivery Maternity" });
    }
    if (location.pathname === PATH.WASH_REGISTER_DASHBOARD) {
      return setDashboard({ value: 10, label: "Wash Register" });
    }
    return setDashboard(option[0]);
  }, [])
  return (
    <>
      {/*begin::Header Mobile*/}
      <div
        id="kt_header_mobile"
        className={`header-mobile align-items-center ${layoutProps.headerMobileCssClasses}`}
        {...layoutProps.headerMobileAttributes}
      >
        {/*begin::Logo*/}
        {/* <Link to="/">
          <img alt="logo" className="w-75 h-50" src={mainLogo} />
        </Link> */}
        {/*end::Logo*/}
        {(location.pathname === PATH.ANC_DASHBOARD ||
          location.pathname === PATH.IMMUNIZATION_DASHBOARD ||
          location.pathname === PATH.OPD_FIVE_REGISTER_DASHBOARD ||
          location.pathname === PATH.OPD_UNDER_FIVE_REGISTER_DASHBOARD ||
          location.pathname === PATH.PRIMARY_HEALTH_UNIT_DASHBOARD ||
          location.pathname === PATH.HOSPITAL_INPATIENT_REGISTER_DASHBOARD ||
          location.pathname === PATH.POSTNATAL_CARE_DASHBOARD ||
          location.pathname === PATH.LABOUR_DELIVERY_MATERNITY_DASHBOARD ||
          location.pathname === PATH.WASH_REGISTER_DASHBOARD ||
          location.pathname === PATH.MAIN_DASHBOARD) && (
            <div id="kt_header_menu_wrapper" style={{ border: "1px solid white", borderRadius: "6px" }}>
              <Select
                options={modifiedOptions}
                styles={reactSelectCustom}
                className="w230"
                onChange={(value) => handleOnChange(value)}
                value={dashboard}
              />
            </div>)}
        {/*begin::Toolbar*/}
        <div className="d-flex align-items-center">
          {layoutProps.asideDisplay && (
            <>
              {/*begin::Aside Mobile Toggle*/}
              <button
                className="btn p-0 burger-icon burger-icon-left"
                id="kt_aside_mobile_toggle"
              >
                <span />
              </button>
              {/*end::Aside Mobile Toggle*/}
            </>
          )}

          {layoutProps.headerMenuSelfDisplay && (
            <>
              {/*begin::Header Menu Mobile Toggle*/}
              <button
                className="btn p-0 burger-icon ml-4"
                id="kt_header_mobile_toggle"
              >
                <span />
              </button>
              {/*end::Header Menu Mobile Toggle*/}
            </>
          )}

          {/*begin::Topbar Mobile Toggle*/}
          <button
            className="btn btn-hover-text-primary p-0 ml-2"
            id="kt_header_mobile_topbar_toggle"
          >
            <span className="svg-icon svg-icon-xl">
              <FaUser color="#fff" />
            </span>
          </button>
          {/*end::Topbar Mobile Toggle*/}
        </div>
        {/*end::Toolbar*/}
      </div>
      {/*end::Header Mobile*/}
    </>
  );
}
