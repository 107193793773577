const PATH = {
  ROOT: "/",

  // Dashboards
  MAIN_DASHBOARD: "/dashboard",
  OPD_FIVE_REGISTER_DASHBOARD: "/opdOverFiveRegisterdashboard",
  OPD_UNDER_FIVE_REGISTER_DASHBOARD: "/opdUnderFiveRegisterdashboard",
  PRIMARY_HEALTH_UNIT_DASHBOARD: "/primaryHealthUnitdashboard",
  HOSPITAL_INPATIENT_REGISTER_DASHBOARD: "/hospitalInPatientRegisterDashboard",
  POSTNATAL_CARE_DASHBOARD: "/postaNatalCareDashboard",
  LABOUR_DELIVERY_MATERNITY_DASHBOARD: "/labour-delivery-maternity-dashboard",
  WASH_REGISTER_DASHBOARD: "/wash-register-dashboard",
  DUMMY_PAGE: "/notfound",
  IMMUNIZATION_DASHBOARD: "/imz_dashboard",
  OTP_DASHBOARD: "/otp-dashboard",
  DASHBOARD_DETAILS: "/OTP-vs-SC",
  SC_DASHBOARD: "/sc-dashboard",
  SUPPLY_MANAGEMENT_DASHBOARD: "/Supply-Management-dashboard",
  OTP_PATIENT_LISTING: "/otp_patinet-listing",
  EMERGENCY_PREPARENESS_MONTHLY_REPORTING_DASHBOARD: "/emergency-preparedness-monthly-reporting-dashboard",
  PROTECTION_MONTHLY_REPORTING_DASHBOARD: "/protection-monthly-reporting-dashboard",
  ANC_DASHBOARD: "/anc_dashboard",
  ANC_SERVICES: "/anc_dashboard/:mrn/anc_Services",

  //Clinic List
  CLINIC_LIST: "/clinics",
  CLINIC_ADD: "/clinics/add",
  CLINIC_EDIT: "/clinics/:id/edit",
  CLINIC_VIEW: "/clinics/:id/view",
  TALLY_VALIDATION: "/tally/validation",

  //Admin List
  ADMIN_LIST: "/admins",
  ADMIN_ADD: "/admins/add",
  ADMIN_EDIT: "/admins/:id/edit",
  ADMIN_VIEW: "/admins/:id/view",

  //Doctor Section
  DOCTOR_LIST: "/doctors",
  DOCTOR_ADD: "/doctors/add",
  DOCTOR_EDIT: "/doctors/:id/edit",
  DOCTOR_VIEW: "/doctors/:id/view",

  //Caregiver Section
  CARE_GIVER_LIST: "/caregivers",
  CARE_GIVER_ADD: "/caregivers/add",
  CARE_GIVER_EDIT: "/caregivers/:id/edit",
  CARE_GIVER_VIEW: "/caregivers/:id/view",

  //Child List
  CHILD_LIST: "/childs",
  CHILD_EDIT: "/childs/:id/edit",
  CHILD_VIEW: "/childs/:id/view",
  CHILD_IMMUNIZATION: "/childs/:id/childimmunization",
  MANAGE_CHILD_IMMUNIZATION: "/childs/:id/childimmunization/immunization",
  ADD_OBSERVATION: "/childs/:id/childimmunization/addobservation",
  ADD_PRESCRIPTION: "/childs/:id/childimmunization/addprescription",
  EDIT_OBSERVATION: "/childs/:id/childimmunization/:recordId/editobservation",
  EDIT_PRESCRIPTION: "/childs/:id/childimmunization/:recordId/editprescription",
  //Patient List
  PATIENT_LIST: "/patients",
  PATIENT_VIEW: "/patients/:id/view",
  PATIENT_DETAIL_VIEW: "/otp-patient-detail/:id",

  //Others
  LEARNING_CENTRE: "/learning_centre",
  LEARNING_CENTRE_VIDEO_ADD: "/learning_centre/add",
  LEARNING_CENTRE_VIDEO_VIEW: "/learning_centre/:id",

  SCHEDULE: "/schedule",
  SCHEDULE_ADD: "/schedule/add",
  SCHEDULE_EDIT: "/schedule/:id/edit",

  LOGGING_ACTIVITY: "/loggingActivity",

  DHIS_CONFIGURATION: "/dhisconfiguration",
  DHIS_UPLOAD: "/dhisupload",
  DHIS_UPLOAD_ID: "/dhisrequests/view/:id/requestId/:rid/clinicId/:cid/month/:m/year/:y",
  DHIS_REQUESTS: "/dhisrequests",
  DHIS_LOGS: "/dhislogs",
  DHIS2_CONFIGURATION: "/dhis2_configuration",
  ROLES_AND_PERMISSIONS: "/roles-permissions",

  //Error Page
  ERROR404: "/error/error-v1",
};

export { PATH };
